import {Injectable} from '@angular/core';
import {InterfaceService} from './interface-service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {EMPTY, Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {openMessage} from "../store/message/message.actions";
import {Message, MessageType} from "../models/message";
import {TranslateService} from "@ngx-translate/core";

interface ApiError{
  type: string;
  info?: Array<{[key: string]: any}>;
}

interface ApiResponse<T> {
  success: boolean;
  error?: ApiError;
  data: T;
}

export interface ListResponse<T> {
  currentPage: number;
  data: Array<T>;
  totalElements: number;
  totalPages: number;
}

@Injectable({
  providedIn: 'root'
})
export class BaseService implements InterfaceService {
  headers: HttpHeaders;
  RS: string;

  protected getHeaders(): HttpHeaders {
    return this.headers;
  }

  constructor(private http: HttpClient, private store: Store, private translate: TranslateService) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    this.RS = environment.RESTServer;
  }

  protected delete<T>(url: string, options?: {headers?: HttpHeaders}, showError = true) {
    return this.http.delete<ApiResponse<T>>(this.RS + url, { headers: this.getHeaders(), withCredentials: true })
    .pipe(
      map(this.processResponse),
      catchError((error: HttpErrorResponse) => this.handleErrorObservable<T>(error, showError))
    );
  }
 
  protected get<T>(url: string, showError = true): Observable<T> {
    return this.http.get<ApiResponse<T>>(this.RS + url, { headers: this.getHeaders(), withCredentials: true })
      .pipe(
        map(this.processResponse),
        catchError((error: HttpErrorResponse) => this.handleErrorObservable<T>(error, showError))
      );
  }

  protected post<T>(url: string, body: any, showError = true): Observable<T> {
    return this.http.post<ApiResponse<T>>(this.RS + url, body, { headers: this.getHeaders(), withCredentials: true })
      .pipe(
        map(this.processResponse),
        catchError((error: HttpErrorResponse) => this.handleErrorObservable<T>(error, showError))
      );
  }

  protected put<T>(url: string, body: any, showError = true): Observable<T> {
    return this.http.put<ApiResponse<T>>(this.RS + url, body, { headers: this.getHeaders(), withCredentials: true })
      .pipe(
        map(this.processResponse),
        catchError((error: HttpErrorResponse) => this.handleErrorObservable<T>(error, showError))
      );
  }

  private processResponse<T>(response: ApiResponse<T>): T {
    return response.success ? response.data : EMPTY as unknown as T;
  }

  private handleErrorObservable<T>(error: HttpErrorResponse, showError: boolean): Observable<T> {
    if (showError) {
      this.handleError(error.error.error);
    }
    return new Observable<T>();
  }

  private handleError(errorResponse: ApiError) {
    this.store.dispatch(openMessage(this.getMessage(errorResponse)));
    // console.error('An error occurred:', error.message);
  }

  public getMessage(error: ApiError): Message{

    let msg = this.translate.instant('errorTypes.' + error.type);
    if (error.info && error.info[0].details)
      msg += '. ' + this.translate.instant('errorTexts.' + error.info[0].details);
    return new Message(msg, MessageType.ERROR);
  }

  translateVariable(key: string): void {

  }
}
